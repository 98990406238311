<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3">
        {{ $t("risk-management.risk-report") }}
      </h6>
    </b-row>
    <b-card class="mb-4">
      <b-row class="justify-content mb-3">
        <b-col md="3">
          <label class="label-title">Desde fecha de registro de sociedad</label>
          <DatePicker v-model="filters.registrationDateSocietyStart" />
        </b-col>
        <b-col md="3">
          <label class="label-title">Hasta fecha de registro de sociedad</label>
          <DatePicker v-model="filters.registrationDateSocietyEnd" />
        </b-col>
        <b-col md="3">
          <label class="label-title">Por Agente Residente</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.residentAgent">
            <option :value="null"></option>
            <option v-for="option in riskResidentAgents" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ `&gt;${option.valueStart} &lt;${option.valueEnd}` }} - Valor: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Por Antigüedad</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.antiquitySociety">
            <option :value="null"></option>
            <option v-for="option in riskAntiquitySocieties" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ `&gt;${option.valueStart} &lt;${option.valueEnd}` }} - Valor: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Abogado/Firma</label>
          <b-form-input class="rounded-0" size="sm" v-model="filters.lawyerFirm" />
        </b-col>
        <b-col md="3">
          <label class="label-title">Nombre de entidad legal</label>
          <b-form-input class="rounded-0" size="sm" v-model="filters.legalEntity" />
        </b-col>
        <b-col md="3">
          <label class="label-title">Folio</label>
          <b-form-input class="rounded-0" size="sm" v-model="filters.folioNumber" />
        </b-col>
        <b-col md="3">
          <label class="label-title">Creado por</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.userId">
            <option :value="null"></option>
            <option v-for="option in usersRegisters" :key="option.id" :value="option.id">
              {{ option.name }}
            </option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <label class="label-title">Riesgo Por País donde Opera</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.riskCountry"
            @change="changeRiskCountry">
            <option :value="null"></option>
            <option v-for="option in riskCountries" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">País donde opera</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.forCountry">
            <option :value="null"></option>
            <option v-for="option in filterRiskForCountries" :key="option.id" :value="option.id">
              {{ option.country }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Riesgo Tipo por categoría</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.riskCategory"
            @change="changeRiskCategory">
            <option :value="null"></option>
            <option v-for="option in riskCategories" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Tipo por categoría</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.categoryLegalEntity">
            <option :value="null"></option>
            <option v-for="option in filterRiskCategoryLegalEntities" :key="option.id" :value="option.id">
              {{
                $t(
                  `risk-management.descriptions.${formatDescription(
                    option.description,
                    "RiskCategoryLegalEntity"
                  )}`
                )
              }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Riesgo Tipo de sociedad</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.riskType" @change="changeRiskType">
            <option :value="null"></option>
            <option v-for="option in riskTypes" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Tipo de sociedad</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.societyType">
            <option :value="null"></option>
            <option v-for="option in filterRiskSocietyTypes" :key="option.id" :value="option.id">
              {{
                $t(
                  `risk-management.descriptions.${formatDescription(
                    option.description,
                    "RiskSocietyType"
                  )}`
                )
              }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Riesgo por Actividad principal</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.riskEconomicActivity"
            @change="changeRiskEconomicActivity">
            <option :value="null"></option>
            <option v-for="option in riskEconomicActivities" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Actividad principal</label>
          <v-select class="rounded-0" v-model="filters.forEconomicActivity" label="activity"
            :options="filterRiskForEconomicActivities.sort((a, b) => a.activity.localeCompare(b.activity))"
            :reduce="(lf) => lf.id" :clearable="false">
            <template #selected-option="option">
              {{ option.activity }}
            </template>
            <template #option="option">
              {{ option.activity }}
            </template>
          </v-select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Riesgo por Paraíso fiscal</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.taxHaven" @change="changeTaxHaven">
            <option :value="null"></option>
            <option v-for="option in riskOffShore" :key="option.id" :value="option.value">
              {{
                $t(
                  `risk-management.descriptions.${descriptions(
                    option.description
                  )}`
                )
              }}: {{ option.value }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <label class="label-title">Paraíso fiscal</label>
          <select class="form-select form-select-sm rounded-0" v-model="filters.countryTaxHaven">
            <option :value="null"></option>
            <option v-for="option in filterRiskCountriesTaxHaven" :key="option.id" :value="option.id">
              {{ option.country }}
            </option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-end">
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="justify-content-end mb-3">
      <b-col class="text-end">
        <label class="label-title">Paginas a exportar</label>
      </b-col>
      <b-col class="text-start">
        <b-form-input class="rounded-0" size="sm" v-model="pageTake" style="width: 60px" />
      </b-col>
      <b-col class="text-end">
        <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary" class="d-inline-block">
          <b-button class="main-btn me-3" size="sm" @click.prevent="downloadExcel">
            Descargar Excel
          </b-button>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table ref="riskReport" stacked="md" small hover select-mode="single" selectable show-empty
            :empty-text="'No hay registros que mostrar'" :filter="filter" :busy.sync="isBusy" :fields="fields"
            :items="retrieveData" :current-page="currentPage" :per-page="take" @filtered="onFiltered"
            @row-clicked="selectItem">
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div class="text-center text-primary my-2">
                <strong>Cargando datos...</strong>
              </div>
            </template>
            <template #cell(type)="data">
              <span>
                {{
                  $t(
                    `risk-management.descriptions.${parseType(data.item.type)}`
                  )
                }}
              </span>
            </template>
            <template #cell(status)="data">
              {{ data.item.status === 0 ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(legalEntityStatus)="data">
              {{ data.item.legalEntityStatus === true ? "Activo" : "Inactivo" }}
            </template>
          </b-table>

          <div class="text-center" v-if="seeTotals">
            <p>
              <label class="label-title">Porcentage total</label>:
              {{ percentageTotal }}
            </p>
            <p>
              <label class="label-title">Riesgo calculado total</label>:
              {{ calculatedTotal }}
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
          class="my-0 justify-content-end">
          <template #first-text>
            <span><b-icon-chevron-double-left scale="1.3" /></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3" /></span>
          </template>
          <template #next-text>
            <span><b-icon-chevron-right scale="1.3" /></span>
          </template>
          <template #last-text>
            <span><b-icon-chevron-double-right scale="1.3" /></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from "@/components/widgets/DatePicker.vue";
import { legalEntityType } from "@/config/legal-entity-type";
import { riskLevelDescription } from "@/config/risk-level-description";
import { riskLevelDescriptionType } from "@/config/risk-level-description-type";
import { societyType } from "@/config/society-type";
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "risk-report",
  components: {
    DatePicker,
  },
  data() {
    return {
      riskLevelDescriptionType: riskLevelDescriptionType,
      societyType: societyType,
      legalEntityType: legalEntityType,
      riskLevelDescription: riskLevelDescription,
      riskEconomicActivities: [],
      filterRiskForEconomicActivities: [],
      riskForEconomicActivities: [],
      filterRiskSocietyTypes: [],
      riskTypes: [],
      riskSocietyTypes: [],
      riskResidentAgents: [],
      riskAntiquitySocieties: [],
      riskCategories: [],
      filterRiskCategoryLegalEntities: [],
      riskCategoryLegalEntities: [],
      riskCountries: [],
      filterRiskForCountries: [],
      riskForCountries: [],
      usersRegisters: [],
      riskOffShore: [],
      filterRiskCountriesTaxHaven: [],
      fields: [
        {
          key: "lawFirmName",
          label: "Abogado/Firma",
        },
        { key: "legalEntityName", label: "Entidad Legal" },
        { key: "folio", label: "Folio" },
        {
          key: "legalEntityStatus",
          label: "Estado de la Entidad",
          class: "text-center",
        },
        {
          key: "type",
          label: "Tipo de Riesgo",
        },
        {
          key: "value",
          label: "Valor de Riesgo",
          class: "text-center",
        },
        {
          key: "percentage",
          label: "Porcentage",
          class: "text-center",
        },
        {
          key: "calculated",
          label: "Riesgo Calculado",
          class: "text-center",
        },
        {
          key: "calculatedTotal",
          label: "Riesgo Calculado Total",
          class: "text-center",
        },
        {
          key: "status",
          label: "Estado del Riesgo Final",
          class: "text-center",
        },
      ],
      search: "",
      filter: null,
      filters: {},
      isBusy: false,
      currentPage: 1,
      take: 7,
      rows: 1,
      pageTake: 0,
      openedDisable: false,
      percentageTotal: 0,
      calculatedTotal: 0,
      sending: false,
      seeTotals: false,
      loading: false
    };
  },
  async created() {
    this.riskEconomicActivities = await this.getTable("RiskEconomicActivity");
    this.riskForEconomicActivities = await this.getTable(
      "RiskForEconomicActivity"
    );
    this.filterRiskForEconomicActivities = this.riskForEconomicActivities;
    this.riskTypes = await this.getTable("RiskType");
    this.riskSocietyTypes = await this.getTable("RiskSocietyType");
    this.filterRiskSocietyTypes = this.riskSocietyTypes;
    this.riskResidentAgents = await this.getTable("RiskResidentAgent");
    this.riskAntiquitySocieties = await this.getTable("RiskAntiquitySociety");
    this.riskCategories = await this.getTable("RiskCategory");
    this.riskCategoryLegalEntities = await this.getTable(
      "RiskCategoryLegalEntity"
    );
    this.filterRiskCategoryLegalEntities = this.riskCategoryLegalEntities;
    this.riskCountries = await this.getTable("RiskCountry");
    this.riskForCountries = await this.getTable("RiskForCountry");
    this.filterRiskForCountries = this.riskForCountries;
    this.filterRiskCountriesTaxHaven = this.riskForCountries;
    this.riskOffShore = await this.getTable("RiskOffShore");
    this.usersRegisters = await this.getUsers();
  },
  // mounted() {
  //   window.addEventListener('beforeunload', this.handleBeforeUnload);
  // },
  // beforeDestroy() {
  //   window.removeEventListener('beforeunload', this.handleBeforeUnload);
  // },
  // beforeRouteLeave(to, from, next) {
  //   if (this.loading) {
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    async getUsers() {
      try {
        let response =
          await RepositoryFactory.riskLevelRepository.usersCreateFinal();
        return response.data;
      } catch (error) {
        return [];
      }
    },
    async getTable(riskTable) {
      try {
        let response = await RepositoryFactory.riskLevelRepository.getTable(
          riskTable
        );
        return response.data;
      } catch (error) {
        return [];
      }
    },
    closeDisable() {
      this.openedDisable = false;
    },
    filterTable() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          filters: this.filters,
        };
        let response = await RepositoryFactory.riskLevelRepository.getFinalRisk(
          payload
        );
        this.rows = response.data.total;
        this.pageTake = response.data.pages;
        let items = response.data.items;

        const expectedValues = [0, 1, 2, 3, 4, 5, 6];

        const valuesInList = [
          ...new Set(items.map((registro) => registro.type)),
        ];

        this.seeTotals = expectedValues.every((valor) =>
          valuesInList.includes(valor)
        );

        if (this.seeTotals) {
          const percentageTotal = items.reduce(
            (acumulador, item) => acumulador + item.percentage,
            0
          );
          const calculatedTotal = items.reduce(
            (acumulador, item) => acumulador + item.calculated,
            0
          );
          this.percentageTotal = percentageTotal;
          this.calculatedTotal = calculatedTotal.toFixed(2);
        }

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          item.lawFirmName = item.lawFirmName.toUpperCase()
        }

        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    refreshTable() {
      this.$refs.riskReport.refresh();
    },
    parseAndConvertDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
    },
    parseType(value) {
      return this.riskLevelDescriptionType.find((x) => x.value === value).text;
    },
    selectItem(item) {
      console.log(item);
    },
    // handleBeforeUnload(event) {
    //   if (this.loading) {
    //     event.preventDefault();
    //     event.returnValue = '';
    //   }
    // },
    async downloadExcel(event) {
      if (event) {
        event.preventDefault();
      }

      try {
        this.loading = true;
        var take = this.take * this.pageTake;

        if (take > this.rows) {
          take = this.rows;
        }

        let payload = {
          page: 1, // this.$refs.riskReport.currentPage,
          take: take,
          search: this.$refs.riskReport.filter,
          filters: this.filters,
          contentType: "excel",
        };
        let response =
          await RepositoryFactory.riskLevelRepository.getFinalReportExcel(payload);

        if (response != null) {
          var type = response.headers['content-type'];
          console.log(type)

          if (type.includes('text/csv')) {
            this.processCSV(response);
          } else {
            this.downloadBlob(response);
          }

          RepositoryFactory.event.add({
            code: 2004,
            details: "file download",
          });
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.loading = false;
      }
    },
    processCSV(response) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileText = event.target.result;
        this.processCSVInChunks(fileText, response);
      };

      reader.readAsText(response.data);
    },
    processCSVInChunks(fileText, response) {
      const ROWS_PER_FILE = 1000000;
      const lines = fileText.split('\n');
      const headers = lines.shift();
      let chunk = [];
      let fileCount = 1;

      for (let i = 0; i < lines.length; i++) {
        chunk.push(lines[i]);

        if (chunk.length >= ROWS_PER_FILE || i === lines.length - 1) {
          this.saveChunkAsFile(headers, chunk, fileCount, response);
          chunk = [];
          fileCount += 1;
        }
      }
    },
    saveChunkAsFile(headers, chunk, fileCount, response) {
      const csvData = [headers, ...chunk].join('\n');
      const blob = new Blob([csvData], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;

      let fileName = `nivelderiesgo_${fileCount}`;

      const contentDisposition = response.headers['content-disposition'];

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(downloadUrl);
    },
    downloadBlob(response) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);

      let fileName = 'nivelderiesgo';
      const contentDisposition = response.headers['content-disposition'];

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    downloadFile(data, filename, contenType) {
      const blob = new Blob([data], { type: contenType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    triggerDownload(link) {
      const a = document.createElement('a');
      a.href = link;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    formatDescription(value, table) {
      if (table == "RiskCategoryLegalEntity") {
        return this.legalEntityDesriptions(value);
      } else if (table == "RiskSocietyType") {
        return this.societyTypeDesriptions(value);
      } else {
        return this.descriptions(value);
      }
    },
    societyTypeDesriptions(value) {
      return this.societyType.find((x) => x.value === value).text;
    },
    legalEntityDesriptions(value) {
      return this.legalEntityType.find((x) => x.value === value).text;
    },
    descriptions(value) {
      return this.riskLevelDescription.find((x) => x.value === value).text;
    },
    changeRiskCountry() {
      this.filters.forCountry = null;
      this.filterRiskForCountries = this.riskForCountries;
      if (this.filters.riskCountry) {
        this.filterRiskForCountries = this.riskForCountries.filter(
          (x) => x.value == this.filters.riskCountry
        );
      }
    },
    changeRiskCategory() {
      this.filters.categoryLegalEntity = null;
      this.filterRiskCategoryLegalEntities = this.riskCategoryLegalEntities;
      if (this.filters.riskCategory) {
        this.filterRiskCategoryLegalEntities =
          this.riskCategoryLegalEntities.filter(
            (x) => x.value == this.filters.riskCategory
          );
      }
    },
    changeRiskType() {
      this.filters.societyType = null;
      this.filterRiskSocietyTypes = this.riskSocietyTypes;
      if (this.filters.riskType) {
        this.filterRiskSocietyTypes = this.riskSocietyTypes.filter(
          (x) => x.value == this.filters.riskType
        );
      }
    },
    changeRiskEconomicActivity() {
      this.filters.forEconomicActivity = null;
      this.filterRiskForEconomicActivities = this.riskForEconomicActivities;
      if (this.filters.riskEconomicActivity) {
        this.filterRiskForEconomicActivities =
          this.riskForEconomicActivities.filter(
            (x) => x.value == this.filters.riskEconomicActivity
          );
      }
    },
    changeTaxHaven() {
      this.filters.countryTaxHaven = null;
      this.filterRiskCountriesTaxHaven = this.riskForCountries;
      if (this.filters.taxHaven) {
        let isTaxHaven = this.filters.taxHaven === 1 ? false : true;
        this.filterRiskCountriesTaxHaven = this.riskForCountries.filter(
          (x) => x.isTaxHaven == isTaxHaven
        );
      }
    },
  },
};
</script>

<style scoped>
.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}
</style>
